/*############### GOOGLE FONTS ###############*/
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@800&family=Inter:wght@100..900&display=swap");

/*############### VARIABLES CSS ###############*/
:root {
  --header-height: 3.5rem;

  /*########## Colors ##########*/
  --first-color: #ff7f66;
  --acent-color: #ff5f38;
  --title-color: #111235;
  --text-color: #828a91;
  --body-color: #fff;
  --white-color: #fff;
  --orange-color-light: #fff7f5;
  --green-color: #02a86a;
  --red-color: #f2003e;
  --gray-color: #f5f5f6;
  --shadow-color: #f78430;

  /*gradient Colors*/
  --orange-linear-color: linear-gradient(135deg, #feb032 0%, #ff702a 100%);
  --green-linear-color: linear-gradient(135deg, #aedc71 0%, #02a86a 100%);

  /*Shadows*/
  --small-shadow: 0px 4px 32px 0px rgba(39, 69, 190, 0.05);
  --medium-shadow: 0px 6px 44px 0px rgba(39, 69, 190, 0.05);
  --big-shadow: 0px 6px 64px 0px rgba(39, 69, 190, 0.05);

  --shadow-dark: 0px 15px 24px 0px rgba(4, 23, 46, 0.11);

  /*########## Font and typography ##########*/
  --body-font: "Inter", sans-serif;
  --title-font: "Exo", sans-serif;
  --biggest-font-size: 3rem;
  --h1-font-size: 2.5rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.75rem;
  --smaller-font-size: 0.675rem;

  /*########## Font weight ##########*/
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-extra-bold: 800;
  --font-black: 900;

  /*########## z index ##########*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Responsive typography */
@media screen and (min-width: 1024px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*############### BASE ###############*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--white-color);
  font-weight: var(--font-medium);
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  font-weight: var(--font-extra-bold);
  color: var(--title-color);
  font-family: var(--title-font);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button,
input {
  border: none;
  outline: none;
}

button {
  cursor: pointer;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

/*############### REUSABLE CSS CLASSES ###############*/
.section {
  padding-block: 4.5rem 2rem;
}

.section__title {
  font-size: var(--h2-font-size);
  margin-bottom: var(--mb-2);
  text-align: center;
}

.title-acent {
  color: var(--acent-color);
}

.container {
  max-width: 1192px;
  margin-inline: 1.5rem;
}

.grid {
  display: grid;
}

.main {
  overflow: hidden; /*For animation */
}

.text__color-white {
  color: var(--white-color);
}

.text__color-green {
  color: var(--green-color);
}

.text__color-orange {
  color: var(--acent-color);
}

.display-desktop {
  display: none;
}

/*############### CONTACT MODAL ###############*/
.contact__modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* padding: 2rem 1rem; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  background-color: #000000ad;
  z-index: var(--z-modal);
  transition: 0.4s;
  visibility: hidden;
  opacity: 0;
  display: none;
}

.modal-toutch {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;

  width: 100%;
  z-index: -1;
}

.contact__modal-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white-color);
  box-shadow: 0px 4px 32px 0px rgba(39, 69, 190, 0.05);
  padding: 4.5rem 1.5rem 2.5rem;
  overflow-y: scroll;
  /* pointer-events: none; */
  z-index: var(--z-modal);
}

.form__error {
  padding: 1.25rem;
  border: .5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2000;
  background-color: var(--white-color);
  text-align: center;
  box-shadow: 0 4px 12px #dbdbdb5e;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  display: none;
}

.error-color {
  color: var(--red-color);
}

.sucess-color {
  color: var(--green-color);
}

.contact__modal-content::-webkit-scrollbar {
  display: none;
}

.contact__modal-title {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: var(--title-color);
}

.contact__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.contact__form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.contact__modal-input {
  background-color: var(--gray-color);
  padding: 1rem;
  border-radius: 0.5rem;
}

.contact__modal-input input {
  background-color: transparent;
  font-family: var(--body-font);
  font-size: 1rem;
  color: var(--text-color);
}

.input__flex span {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--title-color);
  font-size: 0.875rem;
}

.form__message {
  color: var(--green-color);
  font-size: 0.875rem;
  margin: 1.5rem 0;
  display: none;
}

.form__dropdow-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray-color);
  padding: 1rem;
  border-radius: 0.5rem;
}

.form__dropdow-options {
  background-color: var(--gray-color);
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  display: none;
}

.show-dropdow {
  display: flex;
}

.form__dropdow-item {
  padding: 0.6rem;
  cursor: pointer;
  transition: background 0.3s;
}

.form__dropdow-item:hover {
  background-color: #ebebeb;
}

.checkbox__text {
  color: var(--title-color);
  font-size: var(--small-font-size);
  display: inline-flex;
}

.checkbox__flex {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  margin-bottom: 1.25rem;
}

.checkbox__input {
  cursor: pointer;
}

.show-modal {
  visibility: visible;
  opacity: 1;
  display: grid;
}

/*############### BUTTONS ###############*/
.button {
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: inline-block;
  padding: 1.4rem 2rem;
  background-color: var(--first-color);
  color: var(--white-color);
  font-size: 1rem;
  border-radius: 5rem;
  box-shadow: 0px 24px 50px 0px rgba(255, 127, 102, 0.2);
}

.form__button {
  width: 100%;
  padding: 1rem 2rem;
  background-color: var(--acent-color);
  border-radius: 5rem;
  color: var(--white-color);
  font-weight: var(--font-medium);
}

.button-logos {
  display: none;
}

.button-center {
  margin: 0 auto;
}

.button-complete {
  width: 100%;
}

.button-none {
  display: none;
}

.button-margin {
  margin-top: 5rem;
}

.button-margin-2 {
  margin-top: 2.5rem;
}

/* Button Hover Animation Text */
.button__span {
  text-align: center;
  display: flex;
  flex-direction: column;
  line-height: 110%;
  overflow: hidden;
  position: relative;
}

.button__text-1,
.button__text-2 {
  transition: 0.3s;
  color: var(--white-color);
}

.button__text-2 {
  display: none;
  transform: translateY(1.5rem);
  position: absolute;
}

/*############### KEYFRAMES ###############*/
@keyframes logoMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Fraudes Section */
@keyframes fraudesMoving {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

/*############### HEADER AND NAV ###############*/
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  margin-top: 2rem;
  transition: 0.3s;
}

.nav {
  position: relative;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.nav::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5rem;
  background-color: rgba(70, 70, 70, 0.627);
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  z-index: -1;
}

.nav__logo {
  display: inline-flex;
  align-items: center;
}

.nav__logo img {
  width: 140px;
}

.nav__toggle {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--acent-color);
}

/*############### MOBILE DEVICE ###############*/
.nav__menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: var(--white-color);
  transition: right 0.4s;
  padding: 8rem 0 2rem 3rem;
  z-index: var(--z-fixed);
}

.nav__list {
  display: flex;
  flex-direction: column;
  width: 500px;
  row-gap: 1.5rem;
}

.nav__item {
  height: 64px;
  overflow: hidden;
}

.nav__link {
  /* position: relative; */
  font-family: "krooner", sans-serif;
  color: var(--title-color);
  /* transition: color 0.3s; */
  font-size: 52px;
  line-height: 64px;
  height: 64px;
}

.nav__link:hover .letter,
.nav__link.play .letter {
  transform: translateY(-100%);
}

.nav__link .block:last-child {
  color: var(--acent-color);
}

.nav__link .letter {
  display: inline-block;
  transition: transform 0.6s cubic-bezier(0.76, 0, 0.024, 1);
}

.letter:nth-child(1) {
  transition-delay: 0.03s;
}

.letter:nth-child(2) {
  transition-delay: 0.03s;
}

.letter:nth-child(3) {
  transition-delay: 0.06s;
}

.letter:nth-child(4) {
  transition-delay: 0.09s;
}

.letter:nth-child(5) {
  transition-delay: 0.12s;
}

.letter:nth-child(6) {
  transition-delay: 0.15s;
}

.letter:nth-child(7) {
  transition-delay: 0.18s;
}

.letter:nth-child(8) {
  transition-delay: 0.21s;
}

.letter:nth-child(9) {
  transition-delay: 0.24s;
}

.letter:nth-child(10) {
  transition-delay: 0.27s;
}

.letter:nth-child(11) {
  transition-delay: 0.3s;
}

.letter:nth-child(12) {
  transition-delay: 0.33s;
}

/* .nav__link:hover {
  color: var(--acent-color);
}

.nav__link:hover::before {
  width: 100%;
}

.nav__link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -0.5rem;
  background-color: var(--acent-color);
  left: 0;
  transition: 0.3s;
} */

.nav__close {
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--title-color);
}

/* Show menu */
.show-menu {
  right: 0;
}

/* Change background header */
.scroll-header::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 1000%;
  height: 100%;
  background-color: rgba(70, 70, 70, 0.627);
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  z-index: -1;
}

.scroll-header.header {
  margin-top: 0;
}

.scroll-header .nav::before {
  background-color: transparent;
  backdrop-filter: blur(0px);
  border-radius: 0;
}

/* Active link */

/* Swiper Class */

/*############### HERO ###############*/
.hero {
  position: relative;
  /* height: 100vh; */
}

.hero__data-section {
  display: none;
}

.hero__video-content {
  overflow: hidden;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
}

.hero__video-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.24) 0%,
    rgba(0, 0, 0, 0.24) 100%
  );
  z-index: 1;
}

.hero__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scale: 5;
  transform: translateX(-2.4rem);
}

.hero__data {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.hero__title {
  color: var(--white-color);
  font-size: var(--biggest-font-size);
  font-weight: var(--font-extra-bold);
  margin-bottom: 1.25rem;
}

.hero__description {
  line-height: 21px;
  color: var(--white-color);
  margin-bottom: 2rem;
}

/*############### LOGOS ###############*/
.logos {
  position: relative;
  width: 100%;
}

.logos__mask {
  position: absolute;
  width: 100%;
  height: 52px;
  background-color: var(--body-color);
  border-top-left-radius: 44px;
  border-top-right-radius: 44px;
  top: -3rem;
  z-index: 1;
}

.logos__title {
  display: block;
  text-align: center;
  font-size: var(--h3-font-size);
  font-weight: var(--font-bold);
  color: var(--title-color);
  margin-bottom: 2rem;
}

.logo__moving {
  position: relative;
  /* background-color: red; */
  width: 100%;
  height: 10vh;
  /* border: 1.2px solid #eaeaea; */
  overflow: hidden;
  /* border-radius: 10rem; */
}

.logo__moving-content {
  position: absolute;
  /* background: #02a86a; */
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  width: 100%;
}

.logo__move {
  display: inline-block;
  white-space: nowrap;
  animation-name: logoMove;
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.logo__move-item {
  width: 120px;
  margin: 0 1rem;
  display: inline-block !important;
}

/*############### FEATURES ###############*/
.features__container {
  row-gap: 3rem;
}

.features__image {
  position: relative;
  background-color: var(--orange-color-light);
  border-radius: 36px;
  padding: 2rem 1rem 0 1rem;
}

/* Small Component */
.features__component-small {
  position: absolute;
  background-color: var(--white-color);
  box-shadow: var(--small-shadow);
  width: 120px;
  border-radius: 12px;
  padding: 12px;
  top: 2rem;
  right: -1rem;
}

.features__small-data {
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 12px;
}

.features__small-icon {
  padding: 0.5rem;
  background-color: var(--gray-color);
  display: grid;
  place-items: center;
  border-radius: 4px;
  font-size: 12px;
  color: var(--acent-color);
}

.features__small-text {
  color: var(--title-color);
  font-weight: var(--font-bold);
  font-size: 12px;
}

.features__progress {
  position: relative;
  height: 52px;
  width: 52px;
  background: conic-gradient(#7d2ae8 3.6deg, #ededed 0deg);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
}

.features__progress::before {
  position: absolute;
  content: "";
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--white-color);
}

.features__progress-value {
  position: relative;
  font-size: 10px;
  font-weight: var(--font-bold);
  color: var(--title-color);
}

/* Medium Component */
.features__component-medium {
  position: absolute;
  background-color: var(--white-color);
  border-radius: 12px;
  width: 150px;
  padding: 12px;
  box-shadow: var(--small-shadow);
  right: 1rem;
  bottom: -2rem;
}

.features__medium-data {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 12px;
}

.features__medium-icon {
  padding: 0.6rem;
  font-size: 10px;
  color: var(--acent-color);
  background-color: var(--gray-color);
  border-radius: 6px;
}

.features__medium-text {
  font-size: 12px;
  color: var(--title-color);
  font-weight: var(--font-bold);
}

.features__component-link {
  justify-self: center;
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: var(--acent-color);
  font-size: 0.75rem;
  margin-top: 0.75rem;
}

.features__component-link:hover i {
  transform: translateX(0.25rem);
}

.features__component-link i {
  font-size: 1.25rem;
  transition: 0.3s;
}

/* Features Component Check */
.features__component-check {
  position: absolute;
  background-color: var(--first-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding: 0.35rem 1rem 0.35rem 0.35rem;
  bottom: 9rem;
}

.features__check-icon {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: var(--white-color);
  display: grid;
  place-items: center;
}

.features__check-icon i {
  color: var(--green-color);
}

.features__check-text {
  color: var(--white-color);
  font-weight: var(--font-medium);
  font-size: var(12px);
}

.features__data {
  position: relative;
}

.features__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
  margin-bottom: 1rem;
}

.features__description {
  line-height: 24px;
  margin-bottom: 1.5rem;
}

.features__data-infos {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 2.5rem;
}

.features__data-group {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.features__data-info {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.features__data-icon {
  width: 40px;
  height: 40px;
  box-shadow: var(--shadow-dark);
  background-color: var(--white-color);
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.features__data-icon i {
  color: var(--green-color);
  font-size: 1.25rem;
}

.features__data-text {
  font-size: 14px;
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

.features__decoration {
  position: absolute;
  width: 112px;
  bottom: -1rem;
  right: -1rem;
}

/*############### CLIENTS ###############*/
.clients {
  position: relative;
}

.clients__decoration {
  position: absolute;
  width: 120px;
  bottom: -5rem;
}

.clients__container {
  row-gap: 3rem;
  position: relative;
  background-color: var(--orange-color-light);
  border-radius: 32px;
  overflow: hidden;
  padding: 40px 24px;
}

.clients__title {
  font-size: var(--h1-font-size);
  margin-bottom: 2.5rem;
}

.clients__data-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.25rem;
  margin-top: 2rem;
}

.clients__data-top {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.clients__data-logo {
  background-color: var(--white-color);
  border-radius: 5rem;
  padding: 0.4rem 1.5rem;
}

.clients__data-logo img {
  width: 90px;
}

.clients__card {
  text-align: center;
  display: grid;
  width: 200px;
  background-color: var(--white-color);
  border-radius: 20px;
  box-shadow: var(--small-shadow);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.64) 0%, #fff 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 12px;
}

.clients__card-top {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-self: center;
  margin-bottom: 12px;
}

.clients__card-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--white-color);
  box-shadow: var(--shadow-dark);
  display: grid;
  place-items: center;
}

.clients__card-icon i {
  font-size: 1rem;
  color: var(--green-color);
}

.clients__card-text {
  color: var(--title-color);
  font-size: 12px;
  font-weight: var(--font-semi-bold);
}

.clients__card-image {
  width: 140px;
  justify-self: center;
  margin-bottom: 12px;
}

.clients__card-description {
  line-height: 24px;
  font-weight: var(--font-regular);
  margin-bottom: 14px;
}

.clients__card-link {
  justify-self: center;
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: var(--acent-color);
  font-size: 12px;
}

.clients__card-link:hover i {
  transform: translateX(0.25rem);
}

.clients__card-link i {
  font-size: 1.25rem;
  transition: 0.3s;
}

.clients-1 {
  margin-bottom: 2rem;
}

.clients-2 {
  transform: translateX(4rem);
}

.clients__pattern {
  position: absolute;
  max-width: 980px;
  left: -12rem;
  bottom: -8rem;
}

.clients__shadow {
  position: absolute;
  background-color: var(--acent-color);
  opacity: 0.24;
  filter: blur(52px);
  height: 136px;
  width: 136px;
  border-radius: 50%;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
}

/*############### BEST ###############*/
.best {
  position: relative;
}

.best__container {
  row-gap: 3.5rem;
}

.best__data {
  text-align: center;
}

.best__title {
  font-size: var(--h1-font-size);
  margin-bottom: 1rem;
  padding: 0 2rem;
}

.best__description {
  line-height: 24px;
}

.best__shadow {
  position: absolute;
  width: 180px;
  height: 180px;
  background-color: var(--shadow-color);
  border-radius: 50%;
  right: -5rem;
  z-index: -1;
  filter: blur(180px);
}

.best__image-2 {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 70%;
  overflow-x: scroll;
  padding-bottom: 1rem;
  border-radius: 20px;
  box-shadow: 0px 6px 64px 0px rgba(39, 69, 190, 0.05);
}

.best__image-2::-webkit-scrollbar {
  height: 6px;
  background: #f5f5f6;
  /* display: visible; */
  display: none;
}

.best__image-2::-webkit-scrollbar-thumb {
  background: #828a91;
  border-radius: 0.5rem;
}

.best__image-2::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar-false {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: center;
}

.scrollbar-inner {
  background-color: var(--text-color);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.scrollbar-inner.active {
  background-color: var(--title-color);
}

/*############### MONEY ###############*/
.money__container {
  row-gap: 3rem;
  padding-bottom: 6rem;
}

.money__title {
  font-size: var(--h1-font-size);
  margin-bottom: 1rem;
}

.money__description {
  line-height: 24px;
  margin-bottom: 2rem;
}

.money__image {
  position: relative;
  border-radius: 32px;
  background-color: var(--orange-color-light);
  padding: 2rem 1rem 0 1rem;
}

/* Money Component Services */
.money__component-service {
  position: absolute;
  display: none;
  background-color: var(--white-color);
  box-shadow: var(--small-shadow);
  border-radius: 16px;
  width: 180px;
  padding: 14px;
  /* display: flex; */
  flex-direction: column;
  row-gap: 1rem;
}

.component__service-infos {
  display: flex;
  align-items: center;
  column-gap: 0.35rem;
}

.component__service-icon {
  color: var(--green-color);
  font-size: 0.875rem;
}

.component__service-text {
  font-size: 10px;
}

/* Money Component Advantages */
.money__component-advantages {
  position: absolute;
  top: 4rem;
  right: -2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: flex-start;
  scale: 0.8;
}

.money__advantages-item {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  background-color: var(--white-color);
  border-radius: 5rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.money__icon-bg {
  background-color: var(--green-color) !important;
}

.money__advantages-icon {
  width: 24px;
  height: 24px;
  background-color: var(--acent-color);
  color: var(--white-color);
  font-size: 1rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

/* Money Component Rate */
.money__component-rate {
  position: absolute;
  background-color: var(--white-color);
  box-shadow: var(--small-shadow);
  border-radius: 12px;
  width: 232px;
  padding: 12px;
  bottom: -3rem;
  left: 1rem;
}

.component__rate-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.component__rate-data {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.component__rate-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.component__rate-text {
  font-size: 12px;
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.component__rate-quote {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  box-shadow: 0px 6px 20px 0px rgba(7, 17, 54, 0.04);
  background-color: var(--white-color);
  display: grid;
  place-items: center;
}

.component__rate-quote i {
  font-size: 18px;
  color: var(--acent-color);
}

.component__rate-description {
  line-height: 21px;
  font-size: 14px;
}

/*############### OVERVIEW ###############*/
.overview {
  position: relative;
  background-color: var(--orange-color-light);
  border-top-left-radius: 44px;
  border-top-right-radius: 44px;
}

.overview__mask {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
}

.overview__circle {
  position: absolute;
  background-color: var(--white-color);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  box-shadow: 0px 17px 36px rgba(255, 127, 102, 0.12);
  left: 50%;
  transform: translateX(-50%);
  top: -3rem;
}

.overview__circle-img {
  width: 32px;
}

.overview__decoration {
  position: absolute;
  width: 80px;
  top: 1px;
  right: 12px;
}

/* OVERVIEW 1 */
.plataform__container {
  position: relative;
  row-gap: 3rem;
}

.plataform__decoration {
  position: absolute;
  width: 120px;
  bottom: 4rem;
  left: -2rem;
}

.plataform__data {
  text-align: center;
}

.plataform__title {
  font-size: var(--h1-font-size);
  margin-bottom: 2rem;
}

.plataform__shadow {
  position: absolute;
  background-color: var(--acent-color);
  border-radius: 50%;
  opacity: 0.6;
}

.plataform__shadow-1 {
  width: 160px;
  height: 160px;
  right: -8rem;
  top: 8rem;
  filter: blur(120px);
}

.plataform__shadow-2 {
  width: 120px;
  height: 120px;
  left: -7rem;
  bottom: 0;
  filter: blur(80px);
}

/* OVERVIEW 2 */
.fraudes__container {
  row-gap: 3rem;
}

.fraudes__data {
  text-align: center;
}

.fraudes__title {
  font-size: var(--h1-font-size);
  margin-bottom: 1rem;
}

.fraudes__description {
  line-height: 24px;
}

.fraudes__content {
  background-color: var(--acent-color);
  padding: 2rem 1.25rem;
  border-radius: 40px;
  row-gap: 2rem;
}

.fraudes__content-infos {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  transition: 0.3s;
}

.fraudes__content-infos::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  background-color: var(--white-color);
  opacity: 0.5;
  transition: 0.3s;
}

.fraudes__item {
  position: relative;
  padding-left: 1.5rem;
  z-index: 10;
  transition: 0.3s;
}

.fraudes__item::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 0;
  background-color: var(--white-color);
  left: 0;
  z-index: 10;
  transition: 0.3s;
}

.fraudes__header {
  cursor: pointer;
  padding: 0.8rem 0;
}

.fraudes__item-title {
  font-size: var(--h2-font-size);
  color: var(--white-color);
  opacity: 0.45;
  transition: 0.3s;
}

.fraudes__item-content {
  overflow: hidden;
  height: 0;
  transition: 0.3s;
}

.fraudes__item__description {
  line-height: 21px;
  color: var(--white-color);
}

.fraudes__content-image {
  padding: 1.25rem;
  background-color: var(--white-color);
  border-radius: 2rem;
  display: grid;
  place-items: center;
  display: none;
  animation: moving 0.5s ease-out;
}

.fraudes__content-image.fraudes-active-content {
  display: grid;
}

.fraudes__img {
  width: 150px;
}

/* Tabs JS Config */
.accordion-open .fraudes__item-title {
  opacity: 1;
}

.accordion-open::before {
  height: 100%;
}

/* OVERVIEW 3 */
.root__container {
  row-gap: 3rem;
}

.root__image {
  position: relative;
  background-color: var(--white-color);
  padding: 2rem 1.25rem 0 1.25rem;
  border-radius: 32px;
}

.root__component {
  display: flex;
  right: -2rem;
  top: -3rem;
  scale: 0.9;
}

.root__component-image {
  position: absolute;
  bottom: -3rem;
  left: -1.5rem;
  width: 220px;
}

.root__title {
  font-size: var(--h1-font-size);
  margin-bottom: 1rem;
}

.root__description {
  line-height: 24px;
  margin-bottom: 2rem;
}

/*############### ADVANTAGES ###############*/
.advantages {
  position: relative;
}

.advantages__decoration {
  position: absolute;
  width: 120px;
  bottom: -5rem;
  left: -1rem;
}

.advantages__mask {
  position: absolute;
  width: 100%;
  background-color: var(--white-color);
  border-top-left-radius: 44px;
  border-top-right-radius: 44px;
  top: -2rem;
  height: 50px;
}

.advantages__shadow {
  position: absolute;
  border-radius: 50%;
  background-color: var(--shadow-color);
}

.advantages__shadow-1 {
  width: 150px;
  height: 150px;
  filter: blur(120px);
  right: -2rem;
  top: 0;
  opacity: 0.5;
}

.advantages__shadow-2 {
  width: 180px;
  height: 180px;
  filter: blur(150px);
  left: -2rem;
  bottom: 0;
  opacity: 0.6;
  z-index: -1;
}

.advantages__container {
  position: relative;
  row-gap: 3rem;
  z-index: 1;
}

.advantages__data {
  text-align: center;
}

.advantages__title {
  font-size: var(--h1-font-size);
  padding: 0 2rem;
  margin-bottom: 1rem;
}

.advantages__description {
  line-height: 24px;
}

.advantages__content {
  row-gap: 2rem;
}

.advantages__card {
  border: 1.2px solid #eaeaea;
  padding: 1.25rem;
  border-radius: 20px;
  background-color: var(--white-color);
}

.advantages__card-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  box-shadow: var(--shadow-dark);
  display: grid;
  place-items: center;
  margin-bottom: 1.25rem;
}

.advantages__card-icon i {
  font-size: 1.25rem;
  color: var(--title-color);
}

.advantages__card-title {
  font-size: 1rem;
  font-weight: var(--font-bold);
  color: var(--title-color);
  margin-bottom: 0.5rem;
  display: block;
}

.advantages__card-description {
  line-height: 24px;
}

/*############### VERIFY ###############*/
.verify {
  position: relative;
}

.verify__decoration {
  position: absolute;
  width: 120px;
  top: 0;
  right: -1rem;
}

.verify__container {
  padding-top: 3rem;
  row-gap: 3rem;
}

.verify__content {
  row-gap: 2rem;
}

.verify__data {
  text-align: center;
}

.verify__title {
  font-size: var(--h1-font-size);
  margin-bottom: 1rem;
}

.verify__description {
  line-height: 24px;
}
.verify__card {
  background-color: var(--orange-color-light);
  padding: 1.5rem;
  border-radius: 40px;
  overflow: hidden;
}

.verify__card1-top {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.verify__card1-img {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  overflow: hidden;
}

.verify__card1-user {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.verify__card-text {
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.verify__card1-title {
  margin-bottom: 0.8rem;
  font-size: var(--h2-font-size);
  color: var(--title-color);
  font-weight: var(--font-bold);
  display: block;
}

.verify__card1-description {
  line-height: 24px;
  margin-bottom: 1rem;
}

.verify__card-1 {
  overflow: hidden;
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.verify__card1-image {
  transform: translateY(1rem);
}

/* Card 2 */
.verify__card-2 {
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.verify__card2-image {
  transform: translateY(1rem);
}

.verify__card2-data {
  margin-bottom: 1rem;
}

.verify__card2-subtitle {
  display: block;
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
  margin-bottom: 0.8rem;
}

.verify__card2-description {
  line-height: 24px;
  margin-bottom: 1rem;
}

.verify__card2-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-bold);
  color: var(--title-color);
  display: block;
  margin-bottom: 0.8rem;
}

/* Card 3 */
.verify__card3-title {
  display: block;
  margin-bottom: 1.25rem;
  color: var(--title-color);
  font-weight: var(--font-bold);
  font-size: var(--h3-font-size);
}

.verify__card3-subtitle {
  font-weight: var(--font-bold);
  color: var(--title-color);
  display: block;
  margin-bottom: 0.8rem;
}

.verify__card3-description {
  line-height: 24px;
  margin-bottom: 1rem;
}

.card3__description {
  margin-bottom: 2rem;
}

.verify__moving {
  position: relative;
  /* background-color: red; */
  width: 100%;
  height: 10vh;
  border: 1.2px solid #eaeaea;
  overflow: hidden;
  border-radius: 10rem;
}

.verify__moving-content {
  position: absolute;
  /* background: #02a86a; */
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  width: 100%;
}

.verify__move {
  display: inline-block;
  white-space: nowrap;
  animation-name: logoMove;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.verify__move-item {
  cursor: pointer;
  display: inline-block;
  padding: 1rem 1.5rem;
  background-color: var(--gray-color);
  border-radius: 5rem;
  margin: 0 0.6rem;
}

/*############### DASHBOARD ###############*/
.dashboard {
  position: relative;
}

.dashboard__shadow {
  position: absolute;
  border-radius: 50%;
  background-color: var(--shadow-color);
  opacity: 0.8;
  z-index: -1;
}

.dashboard__shadow-1 {
  width: 120px;
  height: 120px;
  right: -3rem;
  filter: blur(120px);
}

.dashboard__shadow-2 {
  width: 150px;
  height: 150px;
  left: -5rem;
  filter: blur(120px);
  bottom: -8rem;
}

.dashboard__contaner {
  row-gap: 3rem;
}

.dashboard__data {
  text-align: center;
}

.dashboard__title {
  font-size: var(--h1-font-size);
  margin-bottom: 1rem;
}

.dashboard__description {
  line-height: 24px;
  margin-bottom: 2rem;
}

.dashboard__content {
  position: relative;
}

/* .dashboard__image {
  position: relative;
} */

.dashboard__component1-image,
.dashboard__component2-image,
.dashboard__component3-image,
.dashboard__decoration {
  position: absolute;
}

.dashboard__decoration-1 {
  bottom: -8rem;
  width: 100px;
  left: -1rem;
}

.dashboard__decoration-2 {
  top: -6rem;
  right: -2rem;
  width: 100px;
}

.dashboard__component1-image {
  width: 110px;
  top: -1rem;
  left: -1rem;
}

.dashboard__component2-image {
  width: 110px;
  bottom: -3rem;
  left: 0;
}

.dashboard__component3-image {
  width: 160px;
  right: 0rem;
  bottom: -6rem;
}

/*############### ANILISE ###############*/
.analise {
  position: relative;
}

.analise__decoration {
  position: absolute;
  width: 120px;
}

.analise__decoration-1 {
  top: 25%;
  right: -2rem;
}

.analise__decoration-2 {
  bottom: 45%;
  left: -0.5rem;
}

.analise__container {
  rotate: 3rem;
  padding-top: 4rem;
}

.analise__content {
  position: relative;
  row-gap: 2rem;
}

.analise__content-title {
  font-size: var(--h1-font-size);
  margin-bottom: 1rem;
}

.analise__content-description {
  line-height: 24px;
}

.analise__content-image {
  position: relative;
}

.analise__content-img {
  width: 320px;
}

.analise__content-component {
  scale: 0.8;
  position: absolute;
  background-color: #eae9e9;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  border-radius: 0.5rem;
}

.analise__component-title {
  display: block;
  font-weight: var(--font-bold);
  color: var(--title-color);
  margin-bottom: 0.25rem;
}

.analise__component-1 {
  left: -2rem;
  top: 7rem;
}

.analise__component-2 {
  right: -2rem;
  bottom: 9rem;
}

.analise__component-3 {
  right: 1.5rem;
  bottom: 5rem;
}

.analise__component-icon {
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  border-radius: 0.35rem;
  background-color: var(--title-color);
}

.analise__component-icon i {
  font-size: 1.25rem;
}

.analise__content-text {
  display: block;
  position: absolute;
  color: var(--title-color);
  font-weight: var(--font-bold);
  top: 5rem;
}

/* Analise infos */
.analise__infos {
  row-gap: 2rem;
}

.analise__infos-title {
  font-size: var(--h1-font-size);
  margin-bottom: 1rem;
}

.analise__infos-description {
  line-height: 24px;
}

.analise__infos-content {
  position: relative;
}

.analise__infos-component {
  background-color: #eaeaeb;
  border-radius: 32px;
  padding: 1.25rem;
}

.analise__infos-text {
  display: block;
  color: var(--title-color);
  font-weight: var(--font-bold);
  margin-bottom: 1rem;
}

.analise__infos-list {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.analise__infos-item {
  position: relative;
  padding: 1rem;
}

.analise__infos-item::before {
  position: absolute;
  content: "";
  height: 100%;
  left: 0;
  top: 0;
  width: 1.5px;
  background-color: var(--acent-color);
}

.analise__infos-item-data {
  color: var(--title-color);
  display: block;
}

.analise__infos-decoration {
  position: absolute;
  width: 120px;
  right: -1.5rem;
  bottom: -2.5rem;
}

/*############### DOCUMENT ###############*/
.document__container {
  row-gap: 3rem;
}

.document__content {
  row-gap: 3rem;
}

.document__title {
  font-size: var(--h1-font-size);
  margin-bottom: 1rem;
}

.document__description {
  line-height: 24px;
  margin-bottom: 1.25rem;
}

.document__subtitle {
  display: block;
  margin-bottom: 2rem;
}

.document__image {
  position: relative;
  background-color: var(--orange-color-light);
  padding: 2rem 1.25rem 0 1.25rem;
  border-radius: 32px;
}

.document__component {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  text-align: center;
  background-color: var(--white-color);
  box-shadow: var(--small-shadow);
  padding: 1rem;
  width: 260px;
  border-radius: 1rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5.5rem;
}

.document__component-icon {
  background-color: #daf2ea;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}

.document__component-icon i {
  font-size: 1.25rem;
  color: var(--green-color);
}

.document__component-text {
  text-align: center;
  color: var(--title-color);
  font-weight: var(--font-bold);
  margin-bottom: 0.8rem;
  font-size: 12px;
}

.document__component-buttons {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.button-black {
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #e8e8ea;
  color: var(--title-color);
  border-radius: 5rem;
}

.button-orange {
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ffeeeb;
  color: var(--acent-color);
  border-radius: 5rem;
}

.document__infos {
  position: relative;
  padding-top: 4rem;
}

.document__infos-grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;
  justify-content: center;
}

.document__info-item {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.document__info-title {
  display: block;
  font-size: var(--h1-font-size);
  font-weight: var(--font-extra-bold);
  color: var(--title-color);
  margin-bottom: 0.5rem;
}

.document__infos-image {
  max-width: 1200px;
  position: absolute;
  bottom: -65%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

/*############### ABOUT ###############*/
.about {
  position: relative;
}

.about__shadow {
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: var(--shadow-color);
  filter: blur(120px);
  opacity: 0.6;
  right: -3rem;
  top: -2rem;
}

.about__container {
  row-gap: 2rem;
}

.about__title {
  font-size: var(--h1-font-size);
  margin-bottom: 1rem;
}

.about__description {
  line-height: 24px;
  margin-bottom: 2rem;
}

.about__data-img {
  width: 100px;
  margin-bottom: 0.5rem;
}

.about__subtext {
  line-height: 24px;
}

.about__image {
  border-radius: 1rem;
  overflow: hidden;
}

/*############### CALL ACTION ###############*/
.action {
  position: relative;
}

.action__shadow {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  filter: blur(120px);
  opacity: 0.8;
  background-color: var(--shadow-color);
  bottom: -2rem;
  left: -3rem;
  z-index: -1;
}

.action__container {
  row-gap: 2rem;
}

.action__title {
  /* margin-bottom: 3rem; */
  font-size: var(--h1-font-size);
}

.action__calendar {
  padding: 2rem 1.25rem;
  /* width: 300px; */
  /* height: 300px; */
  border-radius: 1.25rem;
  background-color: var(--gray-color);
  z-index: 1;
  justify-self: center;
}

.action__calendar-top {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.25rem;
  margin-bottom: 1.5rem;
}

.calendar__button {
  background-color: var(--white-color);
  width: 40px;
  height: 40px;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  color: var(--title-color);
  display: grid;
  place-items: center;
  cursor: pointer;
}

.calendar__month {
  display: inline-flex;
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

/* .calendar__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar__day {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

.calendar-active {
  background-color: var(--acent-color);
  color: var(--white-color);
} */

/* New Calendar */
.calendar__content ul {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.calendar__content ul li {
  position: relative;
  width: calc(100% / 7);
}

.calendar__content .days li::before {
  content: "";
  position: absolute;
  height: 32px;
  width: 32px;
  background-color: var(--gray-color);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.calendar__content .weeks li {
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.calendar__content .days li {
  cursor: pointer;
  margin-top: 24px;
  color: var(--title-color);
}

.days li.inactive {
  color: var(--text-color);
}

.calendar__content .days li:hover::before {
  background-color: var(--acent-color);
}

.calendar__content .days li:hover {
  color: var(--white-color);
}

.days li.active::before {
  background-color: var(--acent-color);
}

.days li.active {
  color: var(--white-color);
}

/*############### FOOTER ###############*/
.footer {
  position: relative;
  background-color: var(--acent-color);
  z-index: 1;
  padding-block: 4rem 2rem;
}

.footer__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 2;
  mix-blend-mode: luminosity;
}

.footer__container {
  row-gap: 3rem;
  position: relative;
  z-index: 3;
  border-bottom: 1px solid var(--white-color);
}

.footer__logo {
  width: 110px;
  margin-bottom: 1rem;
}

.footer__description {
  color: var(--white-color);
  line-height: 24px;
  margin-bottom: 1.25rem;
}

.footer__links {
  display: flex;
  align-items: center;
  column-gap: 1.25rem;
}

.footer__links-item {
  background-color: var(--white-color);
  width: 32px;
  height: 32px;
  color: var(--title-color);
  display: grid;
  place-items: center;
  border-radius: 6px;
}

.footer__link i {
  font-size: 1.25rem;
  color: var(--title-color);
}

.footer__content {
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  padding-bottom: 3rem;
}

.footer__item-title {
  display: inline-block;
  color: var(--white-color);
  font-size: var(1.25rem);
  font-weight: var(--font-bold);
  margin-bottom: 1rem;
}

.footer__item-list {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.footer__item-link {
  font-size: var(--small-font-size);
  color: var(--white-color);
  opacity: 0.8;
  transition: opacity 0.3s;
}

.footer__item-link:hover {
  opacity: 1;
}

.footer__title-bg {
  position: relative;
  color: var(--white-color);
  font-size: 4rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid var(--white-color);
  z-index: 5;
  text-align: center;
}

.footer__copyright {
  position: relative;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.25rem;
  justify-content: center;
  z-index: 3;
}

.footer__copy {
  display: block;
  font-size: var(--small-font-size);
  color: var(--white-color);
}

.footer__terms {
  display: flex;
  align-items: center;
  column-gap: 1.25rem;
}

.footer__terms-text {
  font-size: var(--small-font-size);
  color: var(--white-color);
}
/*############### SCROLL UP ###############*/
.scrollup{
  position: fixed;
  background: var(--white-color);
  right: 1rem;
  bottom: -20%;
  display: inline-flex;
  padding: .3rem;
  box-shadow: 0 4px 12px #d6d6d677;
  border-radius: .25rem;
  z-index: var(--z-tooltip);
  transition: .4s;
}

.scrollup__icon{
  font-size: 1.25rem;
  color: var(--title-color);
  transition: .3s;
}

.scrollup:hover .scrollup__icon {
  transform: translateY(-.25rem);
}

/* Show Scroll Up*/
.show-scroll{
  bottom: 3rem;
}

/*############### LOAD ###############*/
.load {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--white-color);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

/*############### SCROLL BAR ###############*/
/* ::-webkit-scrollbar {
  width: 0.6rem;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 0.5rem;
} */

/*###############  BREAKPOINTS ###############*/
/* For small devices */

@media screen and (min-width: 430px) {
  .hero__data {
    width: 360px;
  }

  .footer__title-bg {
    font-size: 5.5rem;
  }
}

@media screen and (min-width: 576px) {
  .footer__title-bg {
    font-size: 7rem;
  }

  .contact__modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact__modal-content {
    width: 450px;
    height: 80%;
    border-radius: 1.5rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 767px) {
  .contact__modal {
    padding: 2.5rem 0;
  }
  .contact__modal-content {
    width: 650px;
    height: 80%;
  }

  .contact__modal-content::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-false {
    display: none;
  }

  /* BUTTONS */
  .hero-button {
    display: none;
  }

  .button:hover .button__span .button__text-1 {
    transform: translateY(-1.5rem);
  }

  .button:hover .button__span .button__text-2 {
    transform: translateY(0);
  }

  .button-logos {
    display: block;
    margin: 0 auto;
    width: 200px;
  }

  /* Button Hover Animation Blub */
  .button::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0%;
    background-color: var(--acent-color);
    z-index: -1;
    transition: 0.5s;
    border-radius: 150% 150% 0 0;
  }

  .button:hover::before {
    height: 100%;
    border-radius: 0 0 0 0;
  }

  .button-none {
    display: inline-block;
  }

  .button-complete {
    width: initial;
  }

  .mobile-button {
    display: none;
  }

  .display-desktop {
    display: block;
  }

  .display-mobile {
    display: none;
  }

  .button__text-2 {
    display: initial;
  }

  /* HERO */
  .hero__video {
    scale: 3;
    transform: translateX(-4rem);
  }
 
  .hero__data-section {
    display: initial;
    position: absolute;
    z-index: 10;
    text-align: center;
    left: 50%;
    width: 650px;
    transform: translateX(-50%);
    bottom: 5rem;
  }

  .hero__data {
    left: 50%;
    top: auto;
    bottom: 2%;
    width: 500px;
    transform: translate(-50%, -20%);
    display: none;
  }

  /* FEATURES */
  .features__container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    column-gap: 4rem;
  }

  /* .features__title {

  } */

  /* CLIENTS */
  .clients__container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    align-items: center;
    justify-content: space-between;
  }

  .clients__data-logos {
    position: relative;
    z-index: 5;
  }

  .clients__pattern {
    max-width: 1300px;
    transform: translateY(25%);
  }

  /* BEST */
  .best__title {
    padding: 0 12rem;
  }

  .best__description {
    padding: 0 6rem;
  }

  /* MONEY */
  .money__container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    align-items: center;
    justify-content: space-between;
  }

  /* OVERVIEW */
  .overview__decoration {
    width: 130px;
  }

  /* PLATAFORM */
  .plataform__title {
    padding: 0 8rem;
  }

  .plataform__decoration {
    width: 120px;
    bottom: 8%;
  }

  /* Fraudes */
  .fraudes__description {
    padding: 0 6rem;
  }

  .fraudes__content {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: space-between;
    column-gap: 4rem;
  }

  .fraudes__content-images {
    height: 100%;
  }

  .fraudes__content-image {
    height: 100%;
  }

  .fraudes__img {
    width: 220px;
  }

  /* Root */
  .root__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: space-between;
    column-gap: 4rem;
  }

  /* ADVANTAGES */
  .advantages__title {
    padding: 0 12rem;
  }

  .advantages__description {
    padding: 0 7rem;
  }

  .advantages__content {
    grid-template-columns: repeat(2, 250px);
    justify-content: center;
    gap: 2rem;
  }

  /* VERIFY */
  .verify__description {
    padding: 0 4rem;
  }

  .verify__content {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: minmax(150px, auto);
    justify-content: center;
    gap: 2rem;
  }

  .verify__card-1 {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }

  .verify__card-2 {
    grid-column: 4 / 7;
    grid-row: 1 / 2;
  }

  .verify__card-3 {
    grid-column: 1 / 7;
    grid-row: 2 / 2;
  }

  .verify__card-3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: space-between;
    column-gap: 4rem;
  }

  /* DASHBOARD */
  .dashboard__description {
    padding: 0 4rem;
  }

  .dashboard__image {
    width: 460px;
    justify-self: center;
  }

  .dashboard__component1-image {
    left: 15%;
    width: 130px;
  }

  .dashboard__component2-image {
    left: 14%;
    width: 120px;
  }

  .dashboard__component3-image {
    right: 15%;
    width: 170px;
  }

  .dashboard__decoration-1 {
    top: 78%;
    left: 5%;
    z-index: 1;
    width: 120px;
  }

  .dashboard__decoration-2 {
    top: 88%;
    right: 7%;
  }

  /* ANALISE */
  .analise__content-title {
    padding-right: 25rem;
  }

  .analise__content-description {
    padding-right: 20rem;
  }

  .analise__content-img {
    justify-self: center;
  }

  .analise__infos-description {
    padding-right: 13rem;
  }

  .analise__component-1 {
    left: 5%;
    scale: 0.9;
  }

  .analise__content-text {
    left: 7%;
  }

  .analise__component-2 {
    scale: 0.9;
    right: 10%;
  }

  .analise__component-3 {
    scale: 0.9;
    right: 20%;
  }

  .analise__infos-component {
    justify-self: center;
    width: 350px;
  }

  .analise__infos-decoration {
    right: 17%;
  }

  .analise__decoration {
    width: 130px;
  }

  /* DOCUMENT */
  .document__content {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: space-between;
    column-gap: 4rem;
  }

  .document__component {
    bottom: -4rem;
  }

  .document__infos-grid {
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    align-items: flex-start;
  }

  .document__info-title {
    font-size: 3rem;
  }

  /* ABOUT */
  .about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;
    justify-content: space-between;
  }

  /* CALL ACTION */
  .action__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: space-between;
    column-gap: 4rem;
  }

  .action__calendar {
    justify-self: flex-end;
    width: 300px;
  }

  .action__title {
    margin-bottom: 3rem;
    /* font-size: var(--h1-font-size); */
  }

  /* FOOTER */
  .footer__title-bg {
    position: relative;
    font-size: 155px;
    z-index: 5;
  }

  .footer__container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    column-gap: 4rem;
  }
}

/* For large devices */
@media screen and (min-width: 1024px) {
  /* HEADER */
  .nav__menu {
    width: 50%;
    box-shadow: 0 6px 20px #d8d8d850;
  }

  .contact__modal-content {
    width: 750px;
  }

  /* HERO */
  .hero__data {
    width: 850px;
  }

  .hero__data-section {
    width: 900px;
  }

  /* ANALISE */
  .analise__content-title {
    padding-right: 0;
  }

  .analise__content-description {
    padding-right: 0;
  }

  .analise__infos {
    grid-template-columns: repeat(2, 1fr);
  }

  .analise__infos-description {
    padding-right: 0;
  }

  /* CLIENTS */
  .clients__cards {
    justify-self: center;
  }

  /* FOOTER */
  .footer__title-bg {
    font-size: 210px;
  }

  .advantages__decoration {
    z-index: 5;
  }
}

@media screen and (min-width: 1192px) {
  /* REUSABLE CLASS */
  .container {
    margin-inline: auto;
  }

  .section {
    padding-block: 7rem 2rem;
  }

  /* HEADER */
  .nav__menu {
    width: 40%;
  }

  /* HERO */
  /* .hero__video-content {
    height: 116vh !important;
  } */

  .hero__video {
    top: -10%;
    scale: 3;
    transform: translateX(-8rem);
  }

  .hero__data {
    width: 1300px;
    transform: translate(-50%, -70%);
  }

  /* LOGOS */
  .button-logos {
    margin-bottom: 3rem;
  }

  .logos__mask {
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
    height: 200px;
    top: -65%;
    width: 103%;
    left: 50%;
    transform: translateX(-50%);
  }

  .logos__title {
    position: relative;
    z-index: 2;
  }

  .logo__moving {
    position: relative;
    z-index: 2;
  }

  /* FEATURES */
  .features__container {
    grid-template-columns: repeat(2, 520px);
    align-items: center;
    justify-content: center;
    column-gap: 8rem;
  }

  .features__title {
    padding-right: 10rem;
  }

  .features__data-infos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .features__image {
    height: 550px;
    display: grid;
    place-items: center;
  }

  .features__img {
    width: 460px;
  }

  .features__decoration {
    width: 200px;
    bottom: -6rem;
    right: -6rem;
  }

  .features__component-small {
    scale: 1.3;
    top: 16%;
    right: -8%;
  }

  .features__component-medium {
    scale: 1.4;
    right: -5%;
    bottom: -3%;
  }

  .features__component-check {
    bottom: 44%;
    left: -8%;
    scale: 1.1;
  }

  /* CLIENTS */
  .clients__container {
    padding: 3rem;
    border-radius: 4rem;
    grid-template-columns: repeat(2, 500px);
    align-items: center;
    column-gap: 12rem;
    justify-content: space-between;
  }

  .clients__shadow {
    right: 1rem;
  }

  .clients__pattern {
    max-width: 1300px;
    bottom: -20%;
    left: -3rem;
  }

  .clients__data {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .clients__data-logos {
    row-gap: 2rem;
  }

  .clients__data-top {
    column-gap: 12rem;
  }

  .clients__decoration {
    width: 200px;
    bottom: -35%;
  }

  .clients-2 {
    transform: translateX(12rem);
  }

  .clients__cards {
    justify-content: center;
    transform: translateX(-8rem);
  }

  /* BEST */
  .best__title {
    padding: 0 18rem;
  }

  .best__description {
    padding: 0 14rem;
  }

  /* MONEY */
  .money__container {
    grid-template-columns: repeat(2, 520px);
    align-items: center;
    /* column-gap: 8rem; */
    justify-content: space-between;
    padding-bottom: 10rem;
  }

  .money__title {
    padding-right: 8rem;
  }

  .money__image {
    display: grid;
    place-items: center;
  }

  .money__img {
    width: 300px;
  }

  .money__component-service {
    display: flex;
    left: -3.5rem;
    top: -2rem;
  }

  .money__component-rate {
    bottom: -4rem;
  }

  .money__component-advantages {
    scale: 1;
    top: 50%;
    transform: translateY(-50%);
    right: -14%;
  }

  /* OVERVIEW */
  .overview {
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
  }
  .overview__decoration {
    width: 200px;
    top: 5%;
    right: 5%;
  }
  /* overview 1 */
  .plataform__image {
    width: 900px;
    justify-self: center;
  }

  .plataform__title {
    padding: 0 17rem;
  }

  .plataform__decoration {
    position: absolute;
    bottom: 4%;
    width: 200px;
    left: 1%;
    z-index: 1;
  }

  /* overview 2 */
  .fraudes__content {
    padding: 3rem;
    border-radius: 4rem;
    grid-template-columns: repeat(2, 500px);
    justify-content: space-between;
    align-items: center;
  }

  .fraudes__description {
    padding: 0 18rem;
  }

  .fraudes__content-image {
    height: 500px;
  }

  .fraudes__img {
    width: 400px;
  }

  /* overview 3 */
  .root__container {
    grid-template-columns: repeat(2, 500px);
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12rem;
  }

  .root__component-image {
    width: 300px;
    right: -5rem;
    left: initial;
  }

  .root__component {
    right: initial;
    scale: 1.1;
    left: -4rem;
    top: 20%;
  }

  /* ADVANTAGES */
  .advantages__mask {
    width: 100%;
    height: 150px;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
    left: 50%;
    transform: translateX(-50%);
    top: -9%;
  }
  .advantages__title {
    padding: 0 21rem;
  }

  .advantages__description {
    padding: 0 14rem;
  }

  .advantages__content {
    grid-template-columns: repeat(3, 275px);
    gap: 32px;
    justify-content: center;
  }

  .advantages__decoration {
    width: 200px;
    left: -2%;
    bottom: 18%;
  }

  .advantages .section {
    padding-block: 0 initial;
  }

  /* VERIFY */
  .verify__decoration {
    width: 200px;
    top: 6%;
  }

  .verify__description {
    padding: 0 14rem;
  }

  .verify__content {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: minmax(150px, auto);
    justify-content: center;
    gap: 3rem;
  }

  .verify__card-1 {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }

  .verify__card-2 {
    grid-column: 4 / 7;
    grid-row: 1 / 2;
  }

  .verify__card-3 {
    grid-column: 1 / 7;
    grid-row: 2 / 2;
  }

  .verify__card-3 {
    display: grid;
    grid-template-columns: repeat(2, 500px);
    justify-content: space-between;
    padding: 3rem;
  }

  /* DASHBOARD */
  .dashboard__description {
    padding: 0 15rem;
  }

  .dashboard__image {
    width: 650px;
    justify-self: center;
  }

  .dashboard__component1-image {
    scale: 2;
    left: 12%;
    top: 5%;
  }

  .dashboard__component2-image {
    scale: 1.5;
    left: 22%;
  }

  .dashboard__component3-image {
    scale: 1.6;
    right: 16%;
    bottom: -2%;
  }

  .dashboard__decoration-1 {
    width: 200px;
    left: 10%;
    z-index: 1;
  }

  .dashboard__decoration-2 {
    width: 200px;
    right: 2%;
    top: 80%;
  }

  /* ANALISE */
  .analise__content-img {
    justify-self: center;
    width: 550px;
  }

  .analise__content-data {
    position: absolute;
    right: 12%;
    width: 400px;
  }

  .analise__component-1 {
    scale: 1;
    top: 45%;
    left: 6rem;
  }

  .analise__component-2 {
    scale: 1;
    right: 12rem;
    bottom: 38%;
  }

  .analise__component-3 {
    scale: 1;
    right: 16rem;
    bottom: 25%;
  }

  .analise__infos-decoration {
    width: 200px;
    bottom: -22%;
    right: -35%;
  }

  .analise__content-text {
    top: 38%;
    left: 16%;
  }

  .analise__infos {
    grid-template-columns: repeat(2, 500px);
    column-gap: 8rem;
    align-items: flex-start;
  }

  .analise__infos-content {
    width: 320px;
  }

  .analise__decoration-1 {
    width: 250px;
  }

  .analise__decoration-2 {
    width: 200px;
  }

  .analise__decoration-1 {
    top: 15%;
    right: -7%;
  }

  .analise__decoration-2 {
    bottom: -8%;
    left: 8%;
  }

  .analise__infos-data {
    transform: translateX(6.5rem);
  }

  .analise__infos-description {
    padding-right: 6rem;
  }

  /* DOCUMENT */
  .document__content {
    grid-template-columns: repeat(2, 500px);
    align-items: center;
    justify-content: space-between;
  }

  .document__component {
    left: -8%;
    bottom: -6%;
  }

  .document__infos-grid {
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    column-gap: 5rem;
  }

  .document__info-title {
    font-size: 88px;
  }

  .document__infos-image {
    max-width: 1900px;
    bottom: -130%;
  }

  /* ABOUT */
  .about__container {
    grid-template-columns: repeat(2, 500px);
    align-items: center;
    justify-content: space-between;
  }

  .about__image {
    border-radius: 2rem;
  }

  /* ACTION */
  .action__container {
    grid-template-columns: repeat(2, 500px);
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4rem;
  }

  .action__calendar-top {
    margin-bottom: 2rem;
  }

  .action__calendar {
    width: 360px;
    justify-self: flex-end;
  }

  .calendar__content {
    row-gap: 3rem;
  }

  /* FOOTER */
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    padding-bottom: 6rem;
  }

  .footer__content {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer__data {
    width: 350px;
  }

  .footer__title-bg {
    position: relative;
    font-size: 261px;
    z-index: 10;
  }

  .footer__copyright {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1200px) {
  .hero__video {
    top: -10%;
    scale: 3;
  }
}

@media screen and (min-width: 1440px) {
  .hero__data-section {
    bottom: 4rem;
    width: 100%;
  }

  .hero__data-section .hero__title {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1920px) {
  .features,
  .clients,
  .best,
  .money,
  .verify,
  .dashboard,
  .analise,
  .document,
  .about,
  .action {
    max-width: 1440px;
    margin-inline: auto;
  }

  .analise__content-title {
    padding-right: 0;
  }

  .analise__content-description {
    padding-right: 0;
  }

  .plataform__decoration {
    bottom: 10%;
  }

  .logo__moving {
    width: 1192px;
    left: 50%;
    transform: translateX(-50%);
  }

  .logos__mask {
    display: none;
  }
}

/* HEIGHT */
/* @media screen and (min-height: 960px) {
  .hero__video-content {
    height: 115vh !important;
  }
} */

@media screen and (min-height: 1024px) {
  .contact__modal-content {
    height: 770px !important;
  }
}

@media screen and (min-width: 900px) and (min-height: 700px) {
  .contact__modal-content {
    height: 770px !important;
    overflow-y: scroll;
  }
}

/* @media screen and (min-width: 1192px) and (max-height: 837px) {
  .hero__video-content {
    height: 117vh !important;
  }
} */

/* @media screen and (min-width: 1192px) and (min-height: 1024px) {
  .hero__video-content {
    height: 114vh !important;
  }
} */
